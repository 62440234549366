.site-content section,
.site-content header{
  position: relative;
  z-index: 2;

}

.site-content section.bloc-infolettre{
  padding: 6vw 50px;
  background: $white;
  text-align: center;

  position: fixed;
  left: 0;
  bottom: 48px;
  width: 100%;
  z-index: 1;

  .title3{
    color: $colorAccent1;
    max-width: 380px;
    margin: 0 auto;
    font-size: 19px;
    line-height: 1.5;
    padding: 35px;
  }

  i
  {
    position: relative;
    top: 7px;
    display: inline-block;
    color: $colorAccent1;
    font-size: 53px;
    font-weight: 100;
    line-height: 1;
  }

  .input-infolettre{
    position: relative;
    width: 80%;
    max-width: 380px;
    height: auto;
    margin: 0 auto;
    padding: 0 0;

    .form-field{
      position: relative;
    }

    input[type="email"]{
      width: 100%;
      padding: 20px 15px;
      border: none;
      outline: none;
      border-radius: 6px;
      outline: none;
      background: $light;
      color: #181818;
      font-size: 15px;
      font-weight: 400;
    }

    [type="submit"]{
      position: absolute;
      top: 0;
      right: 0;

      width: 60px;
      height: 100%;

      border: none;
      outline: none;
      border-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      overflow: hidden;

      color: #fff;
      background: $colorAccent2;
      text-transform: uppercase;

      i{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        font-size: 28px;
        text-align: center;
        color: $white;
        padding-bottom: 0;
      }
    }

    .parsley-errors-list{
      position: absolute;
      left: 0;
      bottom: -18px;
      width: 100%;
    }

  }

  @media all and (max-width: 1024px) {
    position: relative;
    padding: 40px 20px;
    bottom: 0;
  }

  @media all and (max-width: 799px) {

    .icon
    {
      width: 50px;
    }
    .title3
    {
      font-size: 18px;
    }
  }

  @media all and (max-width: 599px) {

    .title3{
      font-size: 14px;
    }

    .input-infolettre
    {
      width: 100%;
      input[type="email"]{
        width: 100%;
        padding: 15px;
        border: none;
        outline: none;
        border-radius: 6px;
        outline: none;
        color: #181818;
        font-size: 15px;
        font-weight: 400;
      }
    }

  }

}