.init-grid{
  position: relative;

  .row{
    position: relative;
    @extend %clearfix;

    &.max-width{
      max-width: 1400px;
      margin: 0 auto;
    }

  }

  .col{
    position: relative;
    width: 100%;
    &.half{
      float: left;
      width: 50%;
    }
    &.one-third{
      float: left;
      width: 33.3333%;
      &.with-margin{
        width: 32%;
      }
    }
  }

  @media all and (max-width: 1024px) {
    .col{
      &.half{
        float: none;
        width: 100%;
      }
    }
  }
  @media all and (max-width: 767px) {
    .col{
      &.one-third{
        float: none;
        width: 100%;
        padding-bottom: 25px;

        &.with-margin{
          width: 100%;
          max-width: 445px;
          margin-left: auto;
          margin-right: auto;
        }

        .main-text{
          max-width: 300px;
          margin: 0 auto;
        }

      }
      &.small-half{
        float: left;
        width: 50%;
      }
    }
  }
}
