.bloc-video {
  width: 100%;
  background-color: $colorAccent1;
  padding: 5vw 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    max-width: 800px;

    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .youtube-wrap {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
