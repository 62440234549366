#hero {
  background: #f1f2f2 url('/assets/images/hero-home.jpg') no-repeat center / 100% 100%;

  @media screen and (max-width: 768px) {
    background: #f1f2f2 url('/assets/images/hero-mobile.jpg') no-repeat bottom center / 100%;
  }
}

.hero {
  position: relative;
  overflow: hidden;

  img {
    position: relative;
    display: none;
    width: 100%;
    height: auto;

    &.mobile {
      display: none;
    }

  }

  .scroll {
    width: 100%;
    max-width: 25px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -15px;
  }

  .overlay-opacity
  {
    width: 100%;
    min-height: calc( 85vh - 85px );
    background-color: $colorAccent1;
    opacity: 0.5;
  }

  .content {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;

    width: 100%;
    max-width: inherit;
    min-height: inherit;
    padding: 75px;

    z-index: 2;

    text-align: right;

    @media screen and (max-width: 1140px) {
      padding-right: 10%;
    }

    @media screen and (max-width: 768px) {
      justify-content: normal;
      padding-top: 40px;
    }

    @media screen and (max-width: 568px) {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 50px;

      font-size: 40px;
      text-align: left;
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p {
        font-family: $fontTitleBold;
        color: $colorAccent2;
        font-weight: 700;
        font-size: 30px;
        font-style: italic;

        @media screen and (max-width: 568px) {
          font-size: 28px;
        }
      }

      a {
        width: 64px;
        height: 64px;

        i {
          width: 64px;
          height: 64px;
          font-size: 26px;
          color: $colorAccent1;
          border: 2px solid $colorAccent2;
          border-radius: 50%;
          padding: 5px;
        }
      }
    }

    .title1 {
      max-width: 500px;

      padding: 0;

      font-size: 55px;
      color: $colorAccent1;
      font-weight: 700;
      line-height: 1.05;
      font-family: $fontTitle;

      span {
        font-size: 70%;
      }

      @media screen and (max-width: 568px) {
        font-size: 40px;
      }
    }

    .site-btn
    {
      color: #fff;
    }

    .site-hr {
      background: $white;
      margin-bottom: 15px;
    }

    .title2 {
      font-weight: 500;
      font-size: 18px;
      color: $colorAccent1;
    }

  }

}



.hero.fullheight {
  min-height: calc( 85vh - 85px );
  background: $light center center no-repeat;
  background-size: 100%;
  background-position: center center;
  @media all and (max-width: 1000px){
    background-size: cover;
  }
}
