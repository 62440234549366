.bloc-services-wrapper {
  section:first-child, section:last-child {
    .row {
      display: flex;
    }
  }

  section:first-child {
    @media screen and (max-width: 768px) {
      .row {
        flex-direction: column-reverse;
      }
    }
  }

  section:last-child {
    @media screen and (max-width: 768px) {
      .row {
        flex-direction: column;
      }
    }
  }
}

.bloc-services{

  background-color: $white;

  .content{
    float: left;

    .wrap{
      position: relative;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 6vw 50px 0;
      background-color: $white;
      .title3 {
        padding: 0 0 32px;

        font-size: 30px;
        font-weight: 700;
      }
      .main-text
      {
        margin-bottom: 15px;

        color: $dark;
        font-size: 16px;
        line-height: 30px;
      }
    }

    .partners{
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 0 50px;

      .partner-wrap {
        width: 100%;
        border-bottom: 1px solid #ccc;
        &:last-child {
          border: none;
          .partner {
            width: 33%;
          }
        }
        &:after {
          content: '';
          clear: both;
          display: block;
        }
        .partner {
          width: 50%;
          display: inline-block;
          float: left;
          padding: 30px;
          @media all and (max-width: 768px) {
            padding: 10px;
          }
        }
      }
    }
  }

  .visual{
    position: relative;
    float: right;
    top: auto;
    right: auto;
    bottom: auto;

    .img-square {
      position: absolute;
      left: 50%;
      top: 10%;

      width: 225px;
      height: 225px;

      transform: translateX(-50%);
    }

  }

  .services-carousel{

    .carousel-cell{
      width: 100%;
      height: auto;

      img:not(.img-square){
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .flickity-prev-next-button{
      background: transparent;
    }

  }

  @media all and (max-width: 1499px) {
    .col.half.content{
      width: 50%;
    }
    .col.half.visual{
      width: 50%;
    }
  }

  @media all and (max-width: 1023px) {

    .col.half.content{
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      width: 100%;

      .wrap{
        padding: 40px 20px;
      }
    }

    .col.half.visual{
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      width: 100%;
      height: auto;
    }
  }

}
