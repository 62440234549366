.site-content{
  @media all and (max-width: 1024px) {
    padding-bottom: 0;
  }
}
.main-footer{
  position: relative;
  left: 0;
  bottom: 0;
  z-index: 1000;

  width: 100%;
  height: auto;

  padding: 0px;

  background: $light;

  @extend %clearfix;

  .block-share{
    height: 48px;

    text-transform: uppercase;
    letter-spacing: 0;

    background-color: $colorAccent1;

    transition: all 0.2s ease-in-out;

    &:hover
    {
      background-color: $colorAccent2;
      cursor: pointer;
    }

    .main-text{
      line-height: 30px;
      font-weight: 600;
      letter-spacing: 0;
      color: $white;
    }
  }

  .icon-share
  {
    width: 48px;
    height: 48px;
    display: inline-block;
    float: left;
    // background-color: #3b5998;
    margin-left: 5px;
    text-align: center;
    // transition: all 0.2s ease-in-out;
    // &:hover
    // {
    //   background-color: $colorAccent2;
    // }
    i
    {
      color: $white;
      font-size: 20px;
      margin-top: 15px;
      display: inline-block;
    }
  }

  .main-text{
    color: $dark;
    display: inline-block;
    margin: 0;
    padding: 0;
    float: left;
    padding-top: 12px;
    padding-left: 20px;
    font-size: 14px;
    @media all and (max-width: 499px){
      padding-top: 0;
      padding-left: 0;
      padding: 15px 0;
    }
  }

  .left{
    float: left;
  }
  .right{
    float: right;
  }

  @media all and (max-width: 1024px) {
    position: relative;
  }

  @media all and (max-width: 620px) {
    .left{
      float: none;
      width: 100%;
      padding-bottom: 15px;
    }
    .right{
      display: none;
    }
    .main-text{
      width: 100%;
      text-align: center;
    }
  }

}
