
.contact {
  position: relative;

  .bloc-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20%;
    width: 420px;
    background: $white;

    z-index: 2;

    .content {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: auto;
      padding: 60px 50px;

      .title4 {
        text-align: left;
        font-size: 22px;
        margin: 0;
        padding: 0 0 0 0;
        font-weight: 500;
      }

      p {
        padding: 40px 0 0 0;
        margin: 0;

        color: $dark;
        font-size: 16px;
        line-height: 25px;
        text-align: left;
      }

      a{
        transition: all 0.35s ease;
        color: $dark;
        font-size: 16px;
        line-height: 25px;
        text-align: left;

        &:hover{
          color: $colorAccent1;
        }

      }

      i{
        font-size: 150%;
        color: $dark;
      }

    }

  }

  .map{
    position: relative;
    width: 100%;
    height: 50vw;
    min-width: 100%;
    max-width: 100%;
    max-height: 515px;

    z-index: 1;
  }

  @media only screen and (max-width: 1024px) {
    .bloc-text{
      .content{
        padding: 60px 50px;
      }
    }
  }

  @media only screen and (max-width: 1139px) {
    .bloc-text{
      left: 10%;
    }
  }

  @media only screen and (max-width: 919px) {
    .bloc-text{
      left: 7%;
    }
  }

  @media only screen and (max-width: 799px) {
    .bloc-text,
    .bloc-text .content{
      position: relative;
      float: none;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      transform: none;
      width: 100%;

      text-align: center;

      p, .title4{
        text-align: center;
      }

      p:last-child{
        margin-bottom: 0;
        padding-bottom: 0;

        text-align: center;
      }


    }
  }

}