/* --------------------------------

#Vars

-------------------------------- */


$fsl-color1: $colorAccent1;
$fsl-color2: $darker;

$fsl-main-transition: all .45s ease-out;

.admin{
  background: #747881;

  .site-content{
    position: relative;
    padding: 5% 10%;
    // background: url('../images/carrieres.jpg') transparent center center no-repeat;
    background-size: cover;
    background-attachment: scroll;
  }

  .title2{
    text-align: center;
  }

  .site-btn{
    display: block;
    margin: 0 auto;
  }

  .success{
    color: #16a085;
  }

  .danger{
    color: #e74c3c;
  }

  .header {
    position: relative;
    width: 100%;
    height: 95px;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 5px;
    background: #21263a;

    z-index: 9999;

    .hoff-logo{
      display: block;
      float: left;
    }

    .actions{
      position: absolute;
      top: 50%;
      right: 65px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 17px;
      transform: translate(0, -50%);
      line-height: 1;
      margin: 0;
      padding: 0;

      i{
        position: relative;
        top: 2px;
        font-size: 130%;
        margin-left: 5px;
      }
    }

    .pop-menu{
      position: absolute;
      top: -50%;
      right: 65px;
      width: 215px;
      height: auto;
      background: #fff;
      border: 1px solid #eee;

      border-radius: 6px;

      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, .25);

      opacity: 0;

      transition: all 0.45s ease;

      z-index: -1;

      &.open{
        top: 70%;
        opacity: 1;
        z-index: 99;
      }

      a{
        display: block;
        padding: 5% 0;
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        letter-spacing: 1px;

        &:hover{
          background: #eee;
        }

      }

    }

    .gradient{
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: 100%;
      background: url('../images/admin-gradient.jpg') transparent 0 center no-repeat;
      background-size: 100% auto;
    }

  }

  .main-nav {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 25px;
    background-color: white;
    border-bottom: 0;

    .links{
      float: left;
      a{
        float: left;
        position: relative;
        width: auto;
        height: 55px;
        padding: 0 25px;
        color: #333;
        line-height: 55px;
        text-transform: uppercase;
        font-size: 14px;
        &.active{
          &:after{
            content: '';
            position: absolute;
            left: 15%;
            right: 15%;
            bottom: 0;
            height: 3px;
            background: $colorAccent1;
          }
        }
      }
    }

    .add{
      position: absolute;
      top: 50%;
      right: 25px;
      width: auto;
      transform: translate( 0, -50%);
    }

  }

  .button{
    display: inline-block;
    padding: 5px 20px;
    background: $fsl-color1;
    color: #fff;
    border-radius: 4px;
  }

  .main-footer {
    width: 100%;
    height: 95px;
    background: #0f1216;
    border-bottom: 10px solid #4f0808;

    p {
      padding: 0;
      margin: 0;
      color: #fff;
      font-size: 38px;
      text-align: center;
      line-height: 95px;
    }

  }

  .content-heading {
    padding: 10px 40px;
    background-color: #eee;
  }

  .table{
    width: 100%;
    margin: 0 auto;
    border: 0;
    border-collapse: collapse;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;

    tr{
      background: transparent;
      &.pending{
        background: #FFE5D6;
        border-bottom: 3px solid #EFD3C2;
      }
      &.annule{
        display: none;
      }
    }

    th{
      padding: 15px 35px;
      color: #fff;
      font-family: 'Open Sans';
      font-size: .9em;
      font-weight: 600;
      line-height: 2em;
      text-decoration: none;
      text-align: center;
      border: none;
      background: #21263a;
    }

    td{
      padding: 15px 35px;
      color: #333;
      font-size: .8em;
      font-weight: 400;
      line-height: 2em;
      text-decoration: none;
      text-align: center;

      &:first-child,
      &:last-child{
        background: #fbfbfb;
      }

      &.active{
        background: #F9F9F9;
      }

    }

    .action{
      display: inline-block;
      width: 26px;
      height: 26px;
      padding: 0 0;
      border-radius: 50%;
      background-color: $fsl-color1;
      border: 2px solid $fsl-color1;
      color: #e7eaf3;
      font-size: .8em;
      font-weight: 300;
      text-decoration: none;
      text-align: center;
      line-height: 23px;

      &.success {
        background: #16a085;
        border-color: #16a085;
      }

      &.danger {
        background: #e74c3c;
        border-color: #e74c3c;
      }

    }

  }

  .main-footer {
    width: 100%;
    height: 95px;
    padding: 0 0;
    background: #0f1216;
    border-bottom: 10px solid #4f0808;
    z-index: 999;
  }

}

.admin.show{

  .bloc-visual{
    position: relative;
    top: 0;
    right: 0;
    width: 35%;
    height: auto;
    z-index: 5;
    padding: 50px 50px 50px 50px;
    float: left;

    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .bloc-infos{
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 1400px;
    height: auto;
    margin: 0 auto;
    z-index: 5;
    padding: 50px 50px 50px 50px;
    background: #fff;
    border-radius: 6px;

    &:before{
      display: none;
      content: '';

      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(600px, -50%);

      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;

      border-left: 15px solid #f6f6f6;
    }

    .colLeft,
    .colRight{
      float: left;
      width: 50%;
    }

    .row{
      position: relative;
      width: 100%;
      padding: 0 35px 0 0;
      padding-bottom: 35px;
    }

    h1{
      padding-bottom: 15px;
      border-bottom: 2px solid #262626;
    }

    h2 {
      padding: 0 0 5px 0;
      // border-bottom: 1px solid #555;
      font-size: 22px;
    }

    p{
      margin: 0;
      padding: 0;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 1.5;
    }

    .notifications{
      text-align: center;
      .danger{
        display: inline-block;
        color: #e74c3c;
        text-align: center;
        border-top: 1px solid #e74c3c;
        border-bottom: 1px solid #e74c3c;
        margin: 10px;
      }
    }

    .main-form{

      .form-row{
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, .03);
      }

      label{
        color: #e41f39;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1px;

        .fa{
          position: relative;
          top: 2px;
          font-size: 145%;
          cursor: pointer;
          color: #16a085;
        }

        &[for="pubdate"]{
          margin: 0;
        }

      }

      input,
      select,
      textarea {
        width: 100%;
        height: 40px;
        padding: 5px 10px;
        border: none;
        background-color: #eee;
        border-radius: 0;
        font-weight: 400;
        outline: none;
        font-size: 14px;
        letter-spacing: 1px;
        color: #565656;
        margin: 5px 0 15px 0;

        &.list{
          margin-bottom: -5px;
          border-bottom: 1px solid rgba( 0, 0, 0, .10 );
        }
      }

      textarea{
        min-height: 1000px;
      }


      input[disabled],
      select[disabled],
      textarea[disabled]{
        padding-left: 25px;
        padding-right: 25px;
        border: none;
        border-radius: 0;
        background: rgba(0, 0, 0, .02);
        color: #333;
      }

      .form-radio{
        float: left;
        width: 30%;
        text-align: center;
        border: 2px solid #eee;
        border-radius: 6px;
        margin: 0 25px 25px 0;

        input,
        label{
          position: relative;
          display: inline-block;
          width: auto;
          margin: 0 5px;
          padding: 0;
          vertical-align: top;
          height: 40px;
          line-height: 40px;
          color: #132434;
          cursor: pointer;
          text-transform: none;
        }
      }

    }

    .form-field{
      &.fr,
      &.en{
        position: relative;
        width: 50%;
        float: left;
      }
      &.fr{
        padding-right: 20px;
      }
      &.en{
        padding-left: 20px;
      }
      .adder{
        float: right;
        width: 26px;
        height: 26px;
        padding: 0;
        border-radius: 50%;
        background-color: #16a085;
        border: 2px solid #16a085;
        color: #e7eaf3;
        font-size: .8em;
        font-weight: 300;
        text-decoration: none;
        text-align: center;
        line-height: 23px;
        margin: 10px 0;
      }
      .field{
        position: relative;

        &:hover{
          .remover{
            display: block;
          }
        }

        .remover{
          display: none;

          position: absolute;
          top: 50%;
          right: 15px;
          transform: translate( 0, -30%);

          width: 26px;
          height: 26px;
          padding: 0;
          border-radius: 50%;
          background-color: #e41f39;
          border: 2px solid #e41f39;
          color: #e7eaf3;
          font-size: .8em;
          font-weight: 300;
          text-decoration: none;
          text-align: center;
          line-height: 23px;


        }
      }
    }

    .main-form .form-field.membre{
      margin-bottom: 2%;
      border-radius: 6px;
      border: 2px solid #eee;
      padding: 3% 5%;

      &.half{
        width: 48%;
      }

    }



  }

}







/* --------------------------------

Table of content
  -#Imports
  -#Vars
  -#Vb-fullScreenLogin
  -#Parsleyjs - validation assets
  -#Responsive

  -------------------------------- */


/* --------------------------------

#Vb-fullScreenLogin

-------------------------------- */
.vb-fullScreenLogin{

  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: url('../images/cms-background.jpg') transparent center center no-repeat;
  background-size: cover;
  background-attachment: scroll;

  .fsl-gradients{
    position: relative;
    width: 100%;
    height: 100%;

    z-index: 1;




    background: -webkit-linear-gradient(145deg, $fsl-color1 10%, $fsl-color2 90%); /* Chrome 10+, Saf5.1+ */
    background:    -moz-linear-gradient(145deg, $fsl-color1 10%, $fsl-color2 90%); /* FF3.6+ */
    background:     -ms-linear-gradient(145deg, $fsl-color1 10%, $fsl-color2 90%); /* IE10 */
    background:      -o-linear-gradient(145deg, $fsl-color1 10%, $fsl-color2 90%); /* Opera 11.10+ */
    background:         linear-gradient(145deg, $fsl-color1 10%, $fsl-color2 90%); /* W3C */




    opacity: 0.65;

  }

  .fsl-content{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    min-height: 555px;

    z-index: 2;


    backface-visibility: hidden;
    transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
    filter: blur(0);
  }

  .fsl-nav{
    position: relative;
    width: 100%;
    height: auto;
    padding: 25px 0 65px 25px;

    a{
      display: inline-block;
      margin: 0;
      padding: 5px 0;
      font-size: 25px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 35px;
      opacity: 0.5;

      &.active{
        opacity: 1;
        border-bottom: 2px solid #fff;
      }

    }

  }

  .fsl-form-signin{
    position: relative;
    width: 100%;
    height: auto;

    .form-signin-heading{
      display: none;
    }

    label{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      color: #fff;
      padding: 10px 25px;
      margin: 10px 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      opacity: 1;

    }

    input{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      background: rgba(255, 255, 255, .45);
      border: none;
      outline: none;
      border-radius: 25px;
      line-height: 1.5;
      padding: 10px 25px;
      margin: 10px 0;
      font-size: 16px;
      color: $fsl-color2;
      font-weight: 400;
    }

    .checkbox{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding-bottom: 3px;

      input{
        float: left;
        width: auto;
        height: auto;
        margin: 1px 15px 0 0;
        padding: 0;
      }

      label{
        float: left;
        text-transform: none;
        opacity: 1;
        font-size: 16px;
      }

    }

    .btn{
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      background: $fsl-color1;
      border: none;
      outline: none;
      border-radius: 25px;
      line-height: 1.5;
      padding: 12px 25px;
      margin: 10px 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;

      transition: $fsl-main-transition;

      &:hover{
        background: #fff;
        color: $fsl-color2;
      }

    }

    hr{
      position: relative;
      display: block;
      width: 100%;
      height: 3px;
      border: none;
      background: #fff;
      opacity: 0.45;
      border-radius: 25px;
      margin: 75px 0 0 0;
      padding: 0 0;
    }

    .fsl-foot-links{
      position: relative;
      width: 100%;
      height: auto;
      padding: 25px 25px;
      text-align: center;

      a{
        display: inline-block;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        opacity: 1;
        padding: 15px;

        transition: $fsl-main-transition;

        &:hover{
          opacity: 1;
        }

      }

    }

  }


  /* --------------------------------

  #Parsleyjs

  -------------------------------- */
  .fsl-form-signin{
    .parsley-errors-list{
      margin: 0;
      padding: 0 25px;
      list-style-type: none;

      li{
        margin: 0;
        padding: 0 10px;
        border-left: 1px solid #fff;
        color: $fsl-color2;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }








  /* --------------------------------

  #Responsive

  -------------------------------- */

  @media only screen and (max-width: 375px) {
    position: relative;
    width: auto;
    height: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    .fsl-gradients{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
    }

    .fsl-content{
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      transform: translate(0, 0);
      padding: 0 20px;
    }

    .fsl-nav{
      padding-bottom: 35px;

      a{
        margin-right: 15px;
      }

    }
    .fsl-form-signin hr{
      margin-top: 35px;
    }
  }


  @media only screen and (max-width: 320px) {
    .fsl-nav{
      padding-bottom: 0;
    }
    .fsl-form-signin hr{
      margin-top: 0;
    }
    .fsl-form-signin label{
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .fsl-form-signin input{
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .fsl-form-signin .fsl-foot-links{
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }




}




/**
 * Cropper
 *
 */
 .modal-title{
  text-align: center;
}
.site-content section.avatar{
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background: #fff;
  position: initial;
  top: -6px;
  margin-bottom: -60px;
  padding-bottom: 60px;
  .heading{
    padding-top: 45px;
  }
}
.avatar-view {
  cursor: pointer;
  text-align: center;
  img{
    display: inline-block;
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}

.avatar-body {
  padding-right: 15px;
  padding-left: 15px;
}

.avatar-upload {
  overflow: hidden;
  text-align: center;
  p{
    padding: 3% 0;
  }
  label, input[type=file]{
    display: inline-block;
    margin: 0;
    padding: 0;
  }
}

.avatar-upload label {
  display: none;
  width: 100px;
}

.avatar-upload input {
  display: block;
  margin-left: 110px;
}

.avatar-alert {
  margin-top: 10px;
  margin-bottom: 10px;
}

.avatar-wrapper {
  height: 364px;
  width: 100%;
  margin-top: 15px;
  box-shadow: inset 0 0 5px rgba(0,0,0,.25);
  background-color: #fcfcfc;
  overflow: hidden;
}

.avatar-wrapper img {
  display: block;
  height: auto;
  max-width: 100%;
}

.avatar-preview {
  float: left;
  margin-top: 15px;
  margin-right: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
}

.avatar-preview:hover {
  border-color: #ccf;
  box-shadow: 0 0 5px rgba(0,0,0,.15);
}

.avatar-preview img {
  width: 100%;
}

.preview-lg {
  height: 184px;
  width: 184px;
  margin-top: 15px;
}

.preview-md {
  height: 100px;
  width: 100px;
}

.preview-sm {
  height: 50px;
  width: 50px;
}

@media (min-width: 992px) {
  .avatar-preview {
    float: none;
  }
}

.avatar-btns {
  margin-top: 30px;
  margin-bottom: 15px;
}

.avatar-btns .btn-group {
  margin-right: 5px;
}

.loading {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff url("../images/loading.gif") no-repeat center center;
  opacity: .75;
  filter: alpha(opacity=75);
  z-index: 20140628;
}

.table td.drag-handle-col{
  /*padding-left: 8px;
  padding-right: 8px;*/
  text-align: center;
}

.handle{
  display: inline-block;
  cursor: move;
  cursor: -webkit-grabbing;
  font-size: 25px;
  color: #21263a;
}

.select-filter {
  float: right;
  margin-bottom: 40px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  color: white;
  background-color: #21263a;
  padding: 20px;
  border-radius: 6px;

  &.for-categories{
    float: left;
  }

  select {
    background: none;
    border: 1px solid white;
    outline: none;
  }
  option {
    color: #333;
  }
}
