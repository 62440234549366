.twitter-wrapper {
  margin: 0 auto;
  padding: 30px;

  max-width: 600px !important;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}

.youtube-wrap {
	width: 100%;
	max-width: 800px;
	margin: 50px auto;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
