.publication-wrap
{
  width: 100%;

  .capsule-header
  {
    width: 100%;
    min-height: calc( 85vh - 85px );

    background: url('../images/hero.jpg') $light center center no-repeat;
    background-size: cover;

    text-align: center;

    position: relative;

    @media all and (max-width: 767px){
      min-height: 0;
    }

    .video-wrap
    {
      width: 100%;
      max-width: 850px;
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media all and (max-width: 767px){
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }
      .video
      {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;

        iframe
        {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .overlay-opacity
    {
      width: 100%;
      min-height: calc( 85vh - 85px );
      background-color: $colorAccent1;
      opacity: 0.9;
      @media all and (max-width: 767px){
        min-height: 0;
      }
    }

  }

  .publication-header
  {
    width: 100%;
    min-height: calc( 75vh - 85px );

    background: $light center center no-repeat;
    background-size: cover;

    text-align: center;


    [data-bg-cover-img]{
      display: none;
    }

    .overlay-opacity
    {
      width: 100%;
      min-height: calc( 85vh - 85px );
      background-color: $colorAccent2;
      opacity: 0.6;
    }

    .shares
    {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 100;

      .small-title
      {
        padding:0;

        text-transform: uppercase;
        color: $white;
        font-family: $fontText;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0;
        font-weight: 700;
      }

      .icons
      {
        i {
          width: 40px;
          height: 40px;

          padding: 10px;
          margin: 0;

          color: $white;
          font-size: 100%;
          text-align: center;

          border-radius: 50%;

          background: $colorAccent1;
        }
      }
    }

    .content
    {
      position: absolute;
      top: 45%;
      left: 0;
      transform: translate(0, -50%);

      width: 100%;
      height: auto;

      padding-left: 20px;
      padding-right: 20px;

      z-index: 2;

      @media all and (max-width: 767px){
        top: 40%;
      }

      text-align: center;

      .title1
      {
        font-size: 42px;
        margin: 0 auto;
        max-width: 740px;
        color: #fff;
        font-weight: 700;
        line-height: 1.25;
        letter-spacing: 0;
        font-family: $fontTitle;

        @media all and (max-width: 767px){
          font-size: 24px;
          line-height: 1.5;
          padding: 0;
          margin-bottom: 20px;
          max-width: 540px;
        }

        span
        {
          color: $colorAccent1;
        }

      }

    }

  }

  .publication-data
  {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;

    color: $dark;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;

    &:first-of-type{
      padding-top: 50px;
    }

    @media all and (max-width: 767px){
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;
    }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      font-family: $fontText;
      color: $dark;
      font-size: 16px;
      line-height: 33px;
      letter-spacing: 0;
      margin-bottom: 20px;
      @media all and (max-width: 767px){
        font-size: 16px;
        line-height: 28px;
      }
    }

    a {
      font-family: $fontText;
      color: $colorAccent2;
      font-size: 16px;
      line-height: 33px;
      letter-spacing: 0;
      margin-bottom: 20px;
      @media all and (max-width: 767px){
        font-size: 16px;
        line-height: 28px;
      }
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      color: $colorAccent1;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    .ingredients
    {
      width: 40%;
      display: inline-block;
      float: left;
      @media all and (max-width: 767px){
        width: 100%;
      }
      .recette-sub-header{
        h2{
          color: $white;
          margin: 0;
        }
      }
      ul
      {
        background-color: $light;
        padding: 25px 25px 15px 45px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
          list-style-type: none;
          position: relative;
          text-align: left;
        }
        li:before
        {
          content: '';
          display: inline-block;
          width: 13px;
          height: 13px;
          background-image: url('../images/check.png');
          background-size: 13px;
          position: absolute;
          top: 5px;
          left: -25px;
        }
      }
    }

    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      padding-left: 5px;
      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }
      .recette-sub-header
      {
        background-color: $light;
        h2
        {
          color: $colorAccent1;
          margin: 0;
        }
        @media all and (max-width: 767px){
          background-color: $colorAccent1;
          h2
          {
            color: #fff;
          }
        }
      }
      ul
      {
        list-style-type: decimal;
        padding: 25px 25px 15px 35px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .recette-sub-header
    {
      background-color: $colorAccent1;
      padding: 15px 20px;
      color: #fff;
      h2
      {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

  }

}
