.site-btn-center{
  position: relative;
  width: 100%;
  text-align: center;
  @extend %clearfix;
  padding-top: 2vw;
  padding-bottom: 4vw;
}

.site-btn{
  display: inline-block;
  margin: 0;
  padding: 20px 35px;
  border: 2px solid $colorAccent2;
  background: $colorAccent2;
  text-align: center;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: $fontTitle;

  transition: all 0.2s ease-in-out;

  &:hover{
    background: transparent;
    border-color: $colorAccent2;
    color: $colorAccent2;
  }

  &.orange{
    background: $colorAccent1;
    border-color: $colorAccent1;
    color: $white;

     &:hover{
      background: $white;
      color: $colorAccent1;
    }
  }

  &.white{
    background-color: $white;
    border-color: $white;
    color: $dark;

    &:hover{
      background: transparent;
      color: $white;
    }
  }

  &.outlined{
    background: transparent;
    border-color: $colorAccent2;
    color: $colorAccent2;

    &:hover{
      background: $colorAccent2;
      border-color: $colorAccent2;
      color: $white;
    }

    &.orange{
      background: transparent;
      border-color: $colorAccent1;
      color: $colorAccent1;

      &:hover{
        background: $colorAccent1;
        border-color: $colorAccent1;
        color: $white;
      }
    }
  }

  &.contrast{

    &:hover{
      border-color: $colorAccent2;
    }
  }

  @media all and (max-width: 767px) {
    padding: 13px 20px;
    font-size: 14px;
  }

  @media all and (max-width: 599px) {
    padding: 13px 15px;
    font-size: 13px;
  }

}
