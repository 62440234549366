.bloc-arguments-2{
  padding: 0 50px;
  text-align: center;
  background: $white;

  .faits-temporaires {
    width: 100%;
  }

  .row{
    padding: 4vw;
  }

  .top
  {
    overflow: hidden;
    position: relative;
    background-color: $colorAccent1;
    .preparation
    {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 100;
      margin: 0;
      padding: 0;
      display: inline-block;
      background-color: $colorAccent2;
      padding: 3px 5px;
      font-size: 14px;
      color: #fff;
    }
    .play
    {
      width: 100%;
      max-width: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;
      z-index: 100;
    }
    .thumb
    {
      display: block;
      width: 100%;
      height: auto;
      transform: scale(1.01);
      transition: all 0.5s ease-in-out;
      opacity: 1;
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      opacity: 1;
      z-index: 5;

      &.light {
        border: 21px solid rgba($white, .5);
      }

      &.orange {
        border: 21px solid rgba($colorAccent1, .5);
      }

      &.blue {
        border: 21px solid rgba($colorAccent1, .5);
      }
    }

    // &:hover {
    //  .thumb {
    //   transform: scale(1.11);
    //   // opacity: 0.8;
    // }
  // }

}

.title3{
  font-size: 33px;
  font-weight: 700;
  line-height: 38px;
  padding: 0;
}

.title4{
  padding: 0 0 10px 0;
  line-height: 1;
  max-width: 330px;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 400;
  color: $grey;
  letter-spacing: 1px;
}

.title3.text{
  font-size: 16px;
  line-height: 1.5;
}

.listWrap{
  display: flex;
  flex-wrap: wrap;

  @extend %clearfix;
}

.arguments{
  padding: 0 0 0 0;
}

.argument{
  text-align: center;
  margin: 0 .5% 2.5% .5%;
  background: $white;

  &.col-center {
    margin: 0 auto 2.5%;
  }

  .content{
    padding: 40px 15% 0 15%;

    .main-text{
      margin-top: 15px;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

}

&.light{
  background: $white;

  .top{
    background-color: $white;
  }

  .argument{
    background: $white;
  }

  .main-text{
    color: $dark;
  }
}

&.orange{
  background: $colorAccent1;

  .title3 {
    color: $white;
  }

  .top{
    background-color: $colorAccent1;
  }

  .argument{
    background: $colorAccent1;
  }

  .main-text{
    color: $white;
  }
}

&.blue{
  background: $colorAccent2;

  .title3 {
    color: $white;
  }

  .top{
    background-color: $colorAccent2;
  }

  .argument{
    background: $colorAccent2;
  }

  .main-text{
    color: $white;
  }
}

.post-filters {
  margin: 0 0 72px;

  a {
    color: $dark;
    font-family: $fontTitle;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 23px;
    letter-spacing: 0;
    font-size: 20px;

    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}

@media all and (max-width: 1429px) {
  .main-text{
    font-size: 13px;
    line-height: 24px;
  }
}

@media all and (max-width: 1249px) {
  .title4
  {
    font-size: 14px;
  }
}

@media all and (max-width: 1024px) {
  padding: 40px 20px;
}

@media all and (max-width: 1119px) {
  .title4
  {
    min-height: 53px;
  }

  .main-text{
    line-height: 1.6;
  }

  .argument{
    .content{
      padding: 40px 10%;
    }
  }

}

@media all and (max-width: 767px) {
  .title4,
  .main-text
  {
    min-height: inherit;
  }
}

@media all and (max-width: 599px) {

  .title3
  {
    font-size: 18px;
  }
  .arguments
  {
    padding: 0 0;
  }
}

}
