
/*
 *    Colors
 *    ------
 */


$black : #000;
$white : #fff;

$grey : #D1D2D4;
$gray : #707481;

$light : #F1F2F2;
$dark   : #414042;
$darker : #002C50;

$colorAccent1 : #DD5C35;
$colorAccent2 : #8798FF;
$colorAccent3 : #E30613;




/*
 *    Fonts
 *    -----
 */

$fontTitle          : "Asap","Roboto",sans-serif;
$fontTitleBold      : "Asap","Roboto",sans-serif;
$fontText           : "Asap","Roboto",sans-serif;




/*
 *    Breakpoints
 *    -----------
 */

