.title1{
  font-family: $fontTitle;
  text-transform: uppercase;
  color: $colorAccent1;
  margin: 0;
  padding: 0 0 35px 0;
  font-size: 42px;
  line-height: 1.2;
  font-weight: 300;
}

.title2{
  font-family: $fontTitle;
  text-transform: uppercase;
  color: $colorAccent1;
  margin: 0;
  padding: 0 0 25px 0;
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
}

.title3{
  position: relative;
  font-family: $fontTitleBold;
  color: $dark;
  margin: 0;
  width: 100%;
  clear: both;
  padding: 0 0 3vw 0;
  font-size: 24px;
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0;
  text-transform: uppercase;
}

.title4{
  font-family: $fontTitle;
  color: $dark;
  margin: 0;
  padding: 0 0 15px 0;
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
}

.title1, .title2, .title3, .title4{

  i{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    margin-top: -15px;

    font-size: 255%;
    color: $grey;
  }

  strong{
    font-family: $fontTitleBold;
  }

  @media all and (max-width: 969px) {
    i{
      margin-top: 0;
    }
  }

}

.main-text{
  color: $grey;
  margin: 0;
  padding: 0 0 0 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.main-list{
  width: 100%;
  color: $dark;
  margin: 0;
  padding: 25px 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;

  list-style-type: none;

  li{
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
    padding-left: 30px;
    line-height: 1;

    i{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      color: $colorAccent2;
      font-size: 135%
    }

  }

}

a {
  cursor: pointer;
}

.site-hr-container{
  @extend %clearfix;
}

.site-hr{
  display: inline-block;
  width: 55px;
  height: 5px;
  background: $grey;
  margin: 0 auto 25px auto;
  padding: 0;
  border: none;
}
