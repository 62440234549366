#hero-2 {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 25vw;

  margin-bottom: 60px;

  background: #f1f2f2 url('/assets/images/hero-home.jpg') no-repeat center / 75%;

  @media screen and (max-width: 768px) {
    background-size: 100%;
  }

  // background-color: $light;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: 100% 80%;

  // .overlay {
  //   position: absolute;
  //   top: 0;
  //   left: 0;

  //   width: 100%;
  //   height: 100%;

  //   background: rgba($colorAccent1, .5);

  //   opacity: 1;
  // }

  .title1 {
    font-size: 50px;
    font-family: $fontTitle;
    line-height: 80px;
    letter-spacing: 0;
    font-weight: 600;
    text-align: center;
    padding: 0;
    position: relative;
    top: -60px;

    @media all and (max-width: 1260px) {
      font-size: 50px;
    }

    @media all and (max-width: 1260px) {
      font-size: 50px;
      top: -30px;
    }
  }

  @media all and (max-width: 1260px) {
    height: 35vw;
  }

  @media all and (max-width: 800px) {
    height: 45vw;
  }

  @media all and (max-width: 800px) {
    height: 55vw;

    .title1 {
      font-size: 30px;
      position: relative;
      top: -58px;
    }
  }

  @media all and (max-width: 500px) {
    height: 65vw;
  }

}
