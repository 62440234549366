.site-content{
  padding-top: 85px;
  @media all and (max-width: 790px){
    padding-top: 65px;
  }
}

.main-nav{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  height: 85px;

  padding: 20px 50px;

  background: $white;
  border-bottom: 1px solid #eee;

  @media all and (max-width: 790px){
    height: 65px;
  }

  @extend %clearfix;

  transition: all 0.2s ease-in-out;

  .wrap{
    position: relative;
    width: 100%;
    height: 100%;

    :first-child, :nth-child(2){
      z-index: 1;
    }
  }

  .logo{
    position: absolute;
    top: 50%;
    left: 0;

    width: auto;
    height: 45px;

    transform: translate( 0, -50% );

    @media all and (max-width: 1024px){
      height: 35px;
    }
    @media all and (max-width: 790px){
      height: 30px;
    }

    &.mobile{
      display: none;
    }
  }

  .right{
    position: absolute;
    top: 50%;
    right: 0;

    transform: translate( 0, -50% );

    &.mobile{
      display: none;

    }

    @media all and (min-width: 791px){
      &.links {
        position: relative;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
          padding: 0 15px;
        }
      }
    }

    a{
      display: inline-block;
      padding: 5px 15px;

      color: $dark;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;

      @media all and (max-width: 1024px){
        padding: 5px 5px;
      }

      .twitter {
        color: $colorAccent2;
        font-size: 36px;

        &:hover
        {
          color: $colorAccent1;
        }
      }

      &.active
      {
        color: $colorAccent1;
      }

      &:hover
      {
        color: $colorAccent1;
      }
    }

  }

  .burger{
    position: relative;
    width: 37px;
    height: 50px;

    cursor: pointer;

    transform: scale(.8);

    &:hover{
      .lines{
        &:before{
          top: -12px;
        }
        &:after{
          top: 10px;
        }
      }
    }

    &.active{
      .lines{
        background: transparent;
        &:before{
          top: 0;
          background: $colorAccent3;
          transform: rotate(-45deg);
        }
        &:after{
          top: -2px;
          background: $colorAccent3;
          transform: rotate(45deg);
        }
      }
    }

    .lines{
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      display: block;
      width: 100%;
      height: 2px;
      background: $colorAccent2;

      &:before,
      &:after{
        display: block;
        content: '';
        position: relative;
        top: -10px;
        width: 100%;
        height: 100%;
        background: $colorAccent1;

        transition: all 0.25s ease-out 0.1s;
      }

      &:after{
        top: 8px;
      }
    }
  }

  .icons{
    display: inline-block;

    a{
      font-size: 125%;
      &:first-child{
        padding-left: 0;
        padding-right: 0;
      }
      &:last-child{
        padding-left: 10px;
        padding-right: 0;
      }
    }

    i{
      position: relative;
      top: 3px;
    }
  }

  .tel{
    display: none;
    position: relative;
    top: 50%;
    transform: translate(0, 50%);
    float: left;
    margin-right: 25px;
    font-size: 18px;
    font-weight: 500;
    font-family: $fontTitle;

    &.mobile{
      display: none;

    }
  }

  .site-btn{
    display: none;
    float: left;
  }

  &.follow{
    background: $white;

    .site-btn{
      border-color: $colorAccent1;
      color: $white;
      background: $colorAccent1;
      &:hover{
        background: $white;
        color: $colorAccent1;
      }
    }

    .right{
      padding-top: 0;
    }

  }

  @media all and (max-width: 1689px) {

    &,
    &.follow{
      padding: 15px 50px;
    }

  }

  @media all and (max-width: 1024px) {

    &,
    &.follow{
      padding: 15px 20px;
      background: $white;
      border-bottom: 1px solid #eee;

      .tel{
        color: $grey;
      }

      .site-btn{
        border-color: $colorAccent1;
        color: $colorAccent1;
        background: $white;
        &:hover{
          border-color: $colorAccent1;
          background: $colorAccent1;
          color: $white;
        }
      }

      .right{
        padding-top: 0;
      }
    }

  }

  @media all and (max-width: 790px){
    &,
    &.follow{

      .right.mobile{
        display: block;
      }

      .right.links{
        position: fixed;
        top: 65px;
        right: 0;
        left: auto;
        width: 50%;
        min-width: 320px;
        height: calc( 100vh - 65px );
        background: $colorAccent1;
        transform: translate( 100%, 0);

        a,
        .icons{
          display: block;
          width: 100%;
          color: $white;
          text-align: center;
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba( 0, 0, 0, 0.2);

          &:hover{
            opacity: .8;
            background: rgba( 0, 0, 0, .1);
          }
        }

        .icons{
          position: absolute;
          left: 0;
          bottom: 0;
          border-bottom: 5px solid $white;
          &:hover{
            background: transparent;
          }
        }

        .icons a{
          width: auto;
          display: inline-block;
          border: none;
          padding: 15px;
          border-radius: 12px;
        }

      }

      &.active{
        .right.links{
          transform: translate( 0, 0);
          transition: all 0.35s ease;
        }
      }

    }
  }

  @media all and (max-width: 767px) {

    &,
    &.follow{
      padding: 10px 20px;

      .tel{
        transform: translate(0, 40%);
        font-size: 16px;
      }
    }

  }

  @media all and (max-width: 529px) {
    &,
    &.follow{

      .site-btn{
        margin-top: 2px;
        padding: 13px 15px;
        font-size: 12px;
      }

      .tel{
        transform: translate(0,41%);
        font-size: 14px;
        &.mobile{
          transform: translate(0, 9%);
        }
      }
    }
  }

  @media all and (max-width: 469px) {
    &,
    &.follow{
      padding: 7px 20px;

      .tel{
        display: none;

      }

      .site-btn{
        display: none;
      }
    }
  }

}



.nav-links-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: -1;

  background: rgba(0, 0, 0, 0);

  transition: all ease 0.33s;

  @media all and (max-width: 767px) {
    &.active{
      z-index: 98;
      background: rgba(0, 0, 0, .33);
    }
  }

}
